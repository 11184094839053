import logo from './logo.svg';
import React from 'react';
import Header from './Header';
import ProductList from './ProductList';
import './App.css';

const App = () => {
  return (
    <div className="app">
      <Header />
      <ProductList />
    </div>
  );
};

export default App;

