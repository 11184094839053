// src/ProductList.js
import React from 'react';
import Product from './Product';
import './ProductList.css';

const ProductList = () => {
  const products = [
    {
      id: 1,
      name: 'Tiny Foot Picture',
      description: 'Low visibility, low res snapchat screenshot',
      price: 5,
      image: '/foot1.png'
    },
    {
      id: 2,
      name: 'Normal Foot Picture',
      description: 'A normal foot picture that satisfied ur lack-toes intolerance',
      price: 100,
      image: '/foot2.png'
    },
    {
      id: 3,
      name: 'Exemplary Foot Picture',
      description: 'Super rare, super amazing, oiled up high res foot picture',
      price: 10000,
      image: '/foot3.jpeg'
    },
    // Add more products as needed
  ];

  return (
    <div className="product-list">
      {products.map(product => (
        <Product key={product.id} product={product} />
      ))}
    </div>
  );
};

export default ProductList;

